import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class RrDonationButtons extends Controller {
  static targets = ['email']

  resendTaxReceipt() {
    if (this.hasEmailTarget) {
      var url = window.location.pathname;
      var id = url.substring(url.lastIndexOf('/') + 1);
      Rails.ajax({
        url: `/rr_donations/${id}/resend_tax_receipt`,
        dataType: 'script',
        data: `email=${(this.emailTarget).value}`,
        type: "PUT",
        success: function (data) {
          console.log("Success:", data);
        },
        error: function (error) {
          console.error("Error:", error);
        },
      })
    }
  }

}
