import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import dt from 'datatables';
import {} from 'datatables-bootstrap';

export default class RrDonationDataController extends Controller {
  static targets = ['rrPickadate', 'donationsTable']

  initialize() {
    this.dtable = null
  }

  connect() {
    this.flatpickrInstance = null
    this.hookDatePickr()
    this.hookDataTable()
  }


  hookDataTable() {
    if (this.hasDonationsTableTarget) {
      dt.$(this.donationsTableTarget).DataTable({paging: true})
    }
  }

  hookDatePickr() {
    let that = this
    this.flatpickrInstance =
    flatpickr(this.rrPickadateTarget, {
      maxDate: "today",
      onChange: function (selectedDates) {
        const selectedDate = selectedDates[0]
        if (selectedDate){
          const dateStr = selectedDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: "numeric" })
          //$.get('/rr_donations', {"selected_date": dateStr}, null, "script");
          Rails.ajax({
            url: `/rr_donations?selected_date=${dateStr}`,
            dataType: 'script',
            data: `selected_date=${dateStr}`,
            type: "GET",
            success: function () {
              that.hookDataTable()
            },
            error: function (error) {
              console.error("Error:", error);
            },
          });
        }
      },
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "F Y",
          altFormat: "F Y",
          theme: "dark"
        })
      ]
    });
  }

  onCalendarClick() {
    this.flatpickrInstance.open();
  }

  retrieveData() {
    const selectedDate = this.flatpickrInstance.latestSelectedDateObj
    if (selectedDate){
      const dateStr = selectedDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: "numeric" })
      Rails.ajax({
        url: `/rr_donations/retrieve_rr_donation_data`,
        dataType: 'script',
        data: `selected_date=${dateStr}`,
        type: "GET",
        success: function (data) {
          console.log("Success:", data);
        },
        error: function (error) {
          console.error("Error:", error);
        },
      })
    }
  }

  generateRrdonations() {
    const dateStr = this.rrPickadateTarget.value
    if (dateStr) {
      Rails.ajax({
        url: `/rr_donations/build_rr_donations`,
        dataType: 'script',
        data: `selected_date=${dateStr}`,
        type: "GET",
        success: function (data) {
          console.log("Success:", data);
        },
        error: function (error) {
          console.error("Error:", error);
        },
      })
    }
  }

}
